<template lang="pug">
.row.mx-0.input-card
  .d-none.d-xl-inline-block.left-section
    .row.mx-0
      ProgressCard(
        imageIcon="add",
        :currentStep="4",
        stepTitle="Policy Add-ons",
        @handleLeftClick="prevStep",
        @handleRightClick="updateForm")
      p Beyond insurance, companies like yours offer other health and wellness benefits to their employees.
      p  In case you're unsure, you can always come back and include policy-addons at a later time.

    img.pec-image(:src="require(`@/assets/images/pec-addons.svg`)")
  .d-inline-block.col.h-100.overflow-auto
    .py-2
    .container-pec-mobile.mx-auto
      .row.mx-0.mt-4.d-xl-none
        ProgressCard(
          imageIcon="add",
          :currentStep="4",
          stepTitle="Policy Add-ons",
          @handleLeftClick="prevStep",
          @handleRightClick="updateForm")
        p Beyond insurance, companies like yours offer other health and wellness benefits to their employees.
        p  In case you're unsure, you can always come back and include policy-addons at a later time.
      b-form.py-4(@submit.prevent="updateForm()")
        vue-form-generator(:schema="resDef.schema", :model="prospectState", :options="resOptions", ref="formData")
        .col-12.col-lg-8.py-4.mx-auto
          n-button(
            variant="dark",
            block,
            buttonText="Continue",
            rightImageIcon="chevron-right",
            type="submit",
            @click="$refs.formData.validate()",
            @keydown.enter="$refs.formData.validate()")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import resDefs from "./definitions";
import ProgressCard from "./components/ProgressCard.vue";
export default {
  components: {
    NButton,
    ProgressCard,
  },
  props: {
    prospectState: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const resName = "addons";
    return {
      resName,
      resDef: resDefs[resName],
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  methods: {
    async updateForm() {
      await this.$refs.formData.validate();
      if (this.$refs.formData.errors.length > 0) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message:
            "Please enter valid details before continuing to the next step",
        });
      } else {
        this.$store.commit("clearAlerts");
        this.$emit("saveChanges", false);
        this.nextStep();
      }
    },
    nextStep() {
      this.$router.push({
        name: "reviewPlan",
        params: { id: this.$route.params.id },
      });
    },
    prevStep() {
      this.$router.push({
        name: "pricingTable",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
